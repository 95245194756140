import * as envConstants from "../../env/constants";
import StaticContentService from '../../base/staticContentService';

class DonateService {

    getDonate() {
        return StaticContentService.getData(envConstants.DONATE_URL);
    }

}

const instance = new DonateService();
export default instance;