import * as envConstants from "../../env/constants";
import StaticContentService from '../../base/staticContentService';

class AboutService {

    getAboutData() {
        return StaticContentService.getData(envConstants.ABOUT_URL);
    }

}

const instance = new AboutService();
export default instance;