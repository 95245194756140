import * as envConstants from "../../env/constants";
import StaticContentService from '../../base/staticContentService';

class BlogService {

    getBlogs() {
        return StaticContentService.getData(envConstants.BLOGS_URL);
    }

}

const instance = new BlogService();
export default instance;