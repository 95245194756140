import axios from 'axios';
import i18n from '../locales/i18n';

class StaticContentService {

    getData(baseUrl) {
        var lanuageTag = i18n.language?.substring(0,2);

        const contentUrl = baseUrl + lanuageTag + ".json";
        return axios.get(contentUrl);
    }

}

const instance = new StaticContentService();
export default instance;