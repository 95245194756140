import * as envConstants from "../../env/constants";
import StaticContentService from '../../base/staticContentService';


class ProjectService {

    getProjects() {
        return StaticContentService.getData(envConstants.PROJECTS_URL);
    }
    
}

const instance = new ProjectService();
export default instance;